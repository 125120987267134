import { FC, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import styles from './card.module.css';
import { UserType } from '~/interfaces/user';
import { useAuth } from '~/auth';
import { NextLink, useTranslate } from '~/i18n';
import { ImageWithPlaceholder } from '~/utils/image-plaiceholder';
import ImageProxy from '../image/image-proxy';
import Axios from '~/utils/axios';
import { sentryCaptureException } from '~/errors/sentry';
import { storeAndSSIDLogin } from '~/utils/ssid-login';

interface HalaCardProps {
   children: React.ReactNode;
   image: string;
   title: string;
   href: string;
   small?: boolean;
   className?: string;
   external?: boolean;
   alt?: string;
   category?: React.ReactNode;
   category_slug?: string;
   isVerified?: boolean;
   venueType?: string;
   venueTypeTitle?: string;
   bookable?: boolean;
   is_dmc?: boolean;
   is_kafu?: boolean;
   optimized_image?: ImageWithPlaceholder;
   type?: 'experiences' | 'events' | 'packages';
   onClick?: () => void;
   venue_id?: string;
}

const Card: FC<HalaCardProps> = ({
   children,
   href,
   image,
   title,
   alt,
   className,
   small,
   external,
   optimized_image,
   onClick,
   // is_dmc,
   // type = 'experiences',
   venue_id,
}) => {
   const router = useRouter();
   const { translate } = useTranslate();
   const Tag = !external ? NextLink : 'a';
   const { authenticated, user, updateUser } = useAuth<UserType>();
   const [loading, setLoading] = useState(false);

   // const typeLabel =
   //    is_dmc && type === 'experiences'
   //       ? translate({ id: 'common:tour' })
   //       : type === 'experiences'
   //         ? translate({ id: 'common:activity' })
   //         : translate({ id: 'common:event' });

   const updateWishlist = async (is_logged_in?: boolean) => {
      if (!authenticated && !is_logged_in) {
         storeAndSSIDLogin({ router });
         return;
      }
      if (loading) return;
      setLoading(true);
      try {
         const { data } = await Axios.post('/user/wishlist', {
            venue_id: venue_id,
         });

         if (is_logged_in) {
            window?.location?.reload();
         }

         const is_wishlist =
            data?.message === 'Successfully Removed from wishlist' ||
            user?.wishlist?.includes(venue_id);

         if (data.status === 'Success') {
            setLoading(false);
            toast(
               <span>
                  {is_wishlist
                     ? translate({ id: 'auth:removed from your wishlist' })
                     : translate({ id: 'auth:added to your wishlist' })}
               </span>,
               {
                  icon: is_wishlist ? (
                     <img
                        src="/icons/profile/heart-primary-border.png"
                        title={translate({ id: 'auth:add to wishlist' })}
                        className="w-6 mx-0.5 my-0.5"
                     />
                  ) : (
                     <img
                        src="/icons/profile/heart-filled.png"
                        title={translate({ id: 'auth:remove from wishlist' })}
                        className="w-6 mx-0.5 my-0.5"
                     />
                  ),
                  style: {
                     background: '#fef6d6',
                  },
               }
            );
            if (is_wishlist) {
               const _wishlist = user?.wishlist?.filter(item => item !== venue_id);
               updateUser({ ...user, wishlist: _wishlist });
            } else {
               const _wishlist = [...user.wishlist, ...[venue_id]];
               updateUser({ ...user, wishlist: _wishlist });
            }
            if (is_logged_in) {
               window?.location?.reload();
            }
         } else {
            throw new Error(translate({ id: 'business:failed toaster' }));
         }
      } catch (error: any) {
         toast.error(error.message);
         sentryCaptureException(error, { tags: { journey: 'business' } });
      } finally {
         setLoading(false);
      }
   };

   const favIcon = useMemo(() => {
      const is_wishlist = user?.wishlist?.includes(venue_id);
      return is_wishlist ? (
         <img
            src="/icons/profile/heart-filled.png"
            title={translate({ id: 'auth:remove from wishlist' })}
            className="mx-[3px] my-[2px] pt-0.5 w-4 h-4"
         />
      ) : (
         <img
            src="/icons/profile/heart.png"
            title={translate({ id: 'auth:add to wishlist' })}
            className="mx-[3px] my-[2px] pt-0.5 w-4 h-4"
         />
      );
   }, [translate, user, venue_id]);

   return (
      <Tag onClick={onClick} href={href} className="flex w-full justify-center">
         <div className={classNames(styles['card'], className, { [styles['small']]: small })}>
            {/* <div className="w-[62px] text-white text-center bg-black/50 ltr:rounded-br-[20px] rtl:rounded-bl-[20px] absolute ltr:left-0 rtl:right-0 top-0 z-10">
               <div className="leading-6 md:leading-8 p-1.5 ">
                  <div className="text-xl md:text-[32px]">{_day}</div>
                  <div className="uppercase text-base md:text-lg rtl:md:text-base">{_month}</div>
                  <div className="text-base md:text-lg leading-5">{_year}</div>
               </div>
            </div> */}
            <div className="absolute z-10 top-4 ltr:right-4 rtl:left-4 flex gap-3 items-center">
               {/* <div className="h-5 px-2 py-1 text-xs uppercase font-semibold leading-none text-white rounded-lg bg-sta-primary ">
                  {typeLabel}
               </div> */}
               <div
                  className="rounded-full h-7 w-7 flex justify-center items-center"
                  style={{ background: 'rgba(0, 0, 0, 0.3)' }}
                  onClick={e => {
                     e.preventDefault();
                     updateWishlist();
                  }}>
                  {favIcon}
               </div>
            </div>
            <div className={styles['img-container']}>
               <ImageProxy
                  layout="responsive"
                  optimized_image={optimized_image}
                  title={title}
                  src={image}
                  alt={alt || ''}
                  height={360}
                  width={360}
                  className={styles['img']}
                  quality={40}
               />
            </div>
            <div className={`relative flex flex-col grow pt-1 ${small ? 'px-3' : ''}`}>
               {children}
            </div>
         </div>
      </Tag>
   );
};

export default Card;

import Image from 'next/legacy/image';

const ImageProxy = ({ src, ...props }: any) => {
   const imageProxyUrl = `/api/image-proxy?url=${encodeURIComponent(src)}`;

   return (
      <Image
         src={imageProxyUrl}
         {...props}
         onError={e => {
            const image = e.target as HTMLImageElement;
            image.srcset = '';
            image.src = '/images/not_found.svg';
         }}
      />
   );
};

export default ImageProxy;

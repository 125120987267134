import { SHA_Hash } from '~/utils/encryption';
import { dataToDataLayer } from './../../utils/data-layers';
export type ProductImpression = {
   id: string;
   name: string;
   brand: string;
   city: string;
   price: string | number;
   list: string;
   position: number | string;
   category: string;
   date: string;
   weekend: boolean;
};

export type CartItem = ProductImpression & {
   quantity: number | string;
   variant: string;
   [key: string]: any;
};

export type User = {
   email: string;
   user_id: string;
   login_status: string;
};

const normalize = (products: ProductImpression[] | CartItem[]) => {
   return products.map(({ category, city, date, weekend, ...rest }) => ({
      category: category,
      dimension18: city,
      dimension19: date,
      dimension20: weekend ? 'weekend' : 'weekday',
      ...rest,
   }));
};

const normalizeUser = ({ email, ...rest }: User) => ({
   email: email ? SHA_Hash(email) : '',
   ...rest,
});

export const productImpressionDataLayer = ({
   products,
   user,
}: {
   products: ProductImpression[];
   user: User;
}) => {
   const impressions = normalize(products);
   dataToDataLayer({
      user: normalizeUser(user),
      event: 'productImpressions',
      ecommerce: {
         currenyCode: 'SAR',
         impressions,
      },
   });
};
export const productClickImpressionDataLayer = ({
   products,
   user,
}: {
   products: ProductImpression[];
   user: User;
}) => {
   const impressions = normalize(products);
   dataToDataLayer({
      user: normalizeUser(user),
      event: 'productClick',
      ecommerce: {
         click: {
            actionField: {
               list: impressions[0].list,
            },
            products: impressions,
         },
      },
   });
};
export const productDetailsImpressionDataLayer = ({
   products,
   user,
}: {
   products: ProductImpression[];
   user: User;
}) => {
   const impressions = normalize(products);
   dataToDataLayer({
      user: normalizeUser(user),
      event: 'productDetail',
      ecommerce: {
         detail: {
            actionField: {
               list: impressions[0].list,
            },
            products: impressions,
         },
      },
   });
};

export const addToCartDataLayer = ({ items, user }: { items: CartItem[]; user: User }) => {
   const impressions = normalize(items);
   dataToDataLayer({
      user: normalizeUser(user),
      event: 'addToCart',
      ecommerce: {
         currenyCode: 'SAR',
         add: {
            products: impressions,
         },
      },
   });
};

export const removeFromCartDataLayer = ({ items, user }: { items: CartItem[]; user: User }) => {
   const impressions = normalize(items);
   dataToDataLayer({
      user: normalizeUser(user),
      event: 'removeFromCart',
      ecommerce: {
         currenyCode: 'SAR',
         remove: {
            products: impressions,
         },
      },
   });
};

export const checkoutDataLayer = ({
   items,
   user,
   step,
}: {
   items: CartItem[];
   user: User;
   step?: number;
}) => {
   const impressions = normalize(items);
   dataToDataLayer({
      user: normalizeUser(user),
      event: 'checkout',
      ecommerce: {
         currenyCode: 'SAR',
         checkout: {
            actionField: {
               step: step || 1,
               option: step === 2 ? 'Login' : 'Proceen to Payment',
            },
            products: impressions,
         },
      },
   });
};

export const applyCouponDataLayer = ({ items, user }: { items: CartItem[]; user: User }) => {
   const impressions = normalize(items);
   dataToDataLayer({
      user: normalizeUser(user),
      event: 'apply_coupon',
      ecommerce: {
         currenyCode: 'SAR',
         checkout: {
            products: impressions,
         },
      },
   });
};

type PurchaseActionField = {
   id: string;
   affiliation: string;
   revenue: string | number;
   tax: string | number;
   shipping: string | number;
   'coupon code': string;
};
export const purchaseDataLayer = ({
   items,
   order,
   user,
}: {
   items: CartItem[];
   order: PurchaseActionField;
   user: User;
}) => {
   const impressions = normalize(items);
   dataToDataLayer({
      user: normalizeUser(user),
      event: 'purchase',
      ecommerce: {
         currenyCode: 'SAR',
         purchase: {
            actionField: order,
            products: impressions,
         },
      },
   });
};

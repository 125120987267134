import Axios from '~/utils/axios';
import Cookies from 'js-cookie';
import { sentryCaptureException } from '~/errors/sentry';

export const setBrowsedExperience = (id: string) => {
   const isLoggedIn = Boolean(Cookies.get('token')?.length);
   const browsedExperiences = JSON.parse(localStorage.getItem('browsedExperiences') || '[]');
   const index = browsedExperiences.indexOf(id);

   if (index === -1) {
      browsedExperiences.push(id);
   }

   if (browsedExperiences.length >= 11) {
      browsedExperiences.shift();
   }

   localStorage.setItem('browsedExperiences', JSON.stringify(browsedExperiences));
   if (isLoggedIn) postBrowsedExperience();
};

export const postBrowsedExperience = async () => {
   const browsedExperiences = JSON.parse(localStorage.getItem('browsedExperiences') || '[]');
   if (browsedExperiences.length >= 10) {
      try {
         const { data } = await Axios.post('/vs/recommendation/create', {
            venue_ids: browsedExperiences,
         });
         if (data.status?.toLowerCase() === 'success') {
            localStorage.setItem('browsedExperiences', JSON.stringify([]));
         } else {
            throw new Error(data?.error || data?.original?.error || 'Something went wrong');
         }
      } catch (e) {
         console.log(e);
         sentryCaptureException(e);
      }
   }
};

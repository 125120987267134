import { format } from 'date-fns';
import { dataToDataLayer } from '~/utils/data-layers';

export const searchLocationDataLayer = (destination: string) => {
   dataToDataLayer({
      event: 'location_search_click',
      eventCategory: 'Experiences',
      eventAction: 'Search',
      eventLabel: 'Location',
      destination_selectected: destination,
   });
};

export const searchDatesDataLayer = ({ start, end }: { start: Date; end: Date }) => {
   const start_date = format(start, 'dd LLL');
   const end_date = format(end, 'dd LLL');
   dataToDataLayer({
      event: 'experience_time_click',
      eventCategory: 'Experiences',
      eventAction: 'Search',
      eventLabel: 'Time',
      date: `${start_date} - ${end_date}`,
   });
};

export const searchButtonDataLayer = () => {
   dataToDataLayer({
      event: 'search_button_click',
      eventCategory: 'Experiences',
      eventAction: 'CTA',
      eventLabel: 'Search',
   });
};

type BannerClickDataLayerParams = {
   package_name: string;
   package_price: string;
   event_location: string;
};
export const bannerClickDataLayer = (params: BannerClickDataLayerParams) => {
   dataToDataLayer({
      event: 'hero_banner_book_now_click',
      eventCategory: 'Experiences',
      eventAction: 'Search',
      eventLabel: 'Book Now',
      ...params,
   });
};

export const destinationClickDataLayer = (destination: string) => {
   dataToDataLayer({
      event: 'summer_destinations_click',
      eventCategory: 'Experiences',
      eventAction: 'Summer Destinations',
      eventLabel: destination,
   });
};

export const categoryClickDataLayer = (category: string) => {
   dataToDataLayer({
      event: 'browse_by_categories_click',
      eventCategory: 'Experiences',
      eventAction: 'Browse Categories',
      eventLabel: category,
   });
};

type ExperienceClickDataLayerParams = BannerClickDataLayerParams & {
   category: string;
   event?: string;
};
export const experienceClickDataLayer = ({
   category,
   event = 'experience_click',
   ...params
}: ExperienceClickDataLayerParams) => {
   dataToDataLayer({
      event,
      eventCategory: 'Experiences',
      eventAction: category,
      eventLabel: 'Book Now',
      ...params,
   });
};

type filterChangeDataLayerParams = {
   name: string;
   value: string;
};

export const filterChangeDataLayer = ({ name, value }: filterChangeDataLayerParams) => {
   dataToDataLayer({
      event: 'experience_filter_click',
      eventCategory: 'Experiences Listing',
      eventAction: 'Filters',
      eventLabel: name,
      filter_value: value,
   });
};

export const filterGoClickDataLayer = (query: string) => {
   dataToDataLayer({
      event: 'search_experience_go_click',
      eventCategory: 'Experiences Listing',
      eventAction: 'Experience List Search',
      eventLabel: 'Go',
      search_keyword: query || '',
   });
};

export const sortFilterClickDataLayer = (sort: string) => {
   dataToDataLayer({
      event: 'sor_by_click',
      eventCategory: 'Experiences Listing',
      eventAction: 'Sort',
      eventLabel: sort,
   });
};

export const experienceShareDataLayer = (params: BannerClickDataLayerParams) => {
   dataToDataLayer({
      event: 'experience_share_click',
      eventCategory: 'Experiences Detail',
      eventAction: 'Share',
      eventLabel: 'Share',
      ...params,
   });
};
export const experienceBookNowDataLayer = (params: BannerClickDataLayerParams) => {
   dataToDataLayer({
      event: 'experience_book_online_click',
      eventCategory: 'Experiences Detail',
      eventAction: 'CTA',
      eventLabel: 'Book Online',
      ...params,
   });
};

export const experienceMessageSupportDataLayer = (params: BannerClickDataLayerParams) => {
   dataToDataLayer({
      event: 'experience_message_support_click',
      eventCategory: 'Experiences Detail',
      eventAction: 'Support',
      eventLabel: 'Message Support',
      ...params,
   });
};
export const experienceCallSupportDataLayer = (params: BannerClickDataLayerParams) => {
   dataToDataLayer({
      event: 'experience_call_support_click',
      eventCategory: 'Experiences Detail',
      eventAction: 'Support',
      eventLabel: 'Call Support',
      ...params,
   });
};
export const experienceGetDirectionstDataLayer = (params: BannerClickDataLayerParams) => {
   dataToDataLayer({
      event: 'experience_get_directions_click',
      eventCategory: 'Experiences Detail',
      eventAction: 'Direction',
      eventLabel: 'Get Direction',
      ...params,
   });
};
export const experienceSimilarCardDataLayer = (params: BannerClickDataLayerParams) => {
   dataToDataLayer({
      event: 'similar_experience_click',
      eventCategory: 'Similar Experience',
      eventAction: 'Direction',
      eventLabel: 'Book Now',
      ...params,
   });
};
export const experiencePhotoClickDataLayer = (params: BannerClickDataLayerParams) => {
   dataToDataLayer({
      event: 'photo_click',
      eventCategory: 'Experiences Detail',
      eventAction: 'Zoom',
      eventLabel: 'Photo',
      ...params,
   });
};

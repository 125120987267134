import Card from '~/components/common/card';
import { ExperienceProps } from '~/interfaces/experience';
import { SwiperSlide } from 'swiper/react';
import { Translate } from '~/i18n';
import { experienceClickDataLayer } from '~/analytics/gtm/events';
import { formatNumber } from './format-price';
import { productClickImpressionDataLayer } from '~/analytics/gtm/ecommerce';
import { setBrowsedExperience } from './set-browsed-experiences';
import { useAuth } from '~/auth';
import { useCurrency } from '~/providers';
import Button from '~/components/common/button';
import { LocationOutlineIcon } from '~/svgs/common';
// import { visiualCitySlug } from './get-city-slug';

export const getPrice = (price: number, discounted_price: number) => {
   if (discounted_price > 0) return formatNumber(discounted_price);
   return formatNumber(price);
};

export const getDiscount = (price: number, discounted_price: number) => {
   if (price > 0) {
      const amount = (1 - discounted_price / price) * 100;
      return formatNumber(amount);
   }
   return '';
};
const getExperienceSlider = (
   array: ExperienceProps[] = [],
   alt?: string,
   analytics_id?: string,
   analytics_event?: string,
   homepage?: boolean
) => {
   return array?.map(
      ({
         _id,
         id,
         images = [],
         slug,
         name,
         venue_image,
         list_price,
         discounted_price,
         city_slug,
         city,
         category: cat,
         is_external,
         verified,
         book_online,
         is_dmc,
         optimized_image,
         venue_type,
         venue_type_title,
         event_is_group,
         type,
         price_start_from,
         organizer_name,
      }) => {
         const { authenticated, user } = useAuth();
         const isOnline = cat?.slug === 'virtual-experiences';
         const isVerified = verified === 'Yes' ? true : false;

         // ! Quick fix to the issue of sometimes discounted price is bigger than actual price (data entry issue)
         list_price = discounted_price > list_price ? discounted_price : list_price;

         const _category = (
            <div className="text-xs md:text-sm line-clamp-1 font-semibold uppercase flex items-center mb-2 md:mb-4 cursor-default">
               <div className="flex items-center gap-1 md:gap-2 whitespace-nowrap">
                  <span className="w-[15px] h-[15px] flex items-center justify-center">
                     <LocationOutlineIcon className="-mt-0.5" width={14} height={14} />
                  </span>
                  <span>{city || ''}</span>
               </div>
               <div className="line-clamp-1">
                  <span className="mx-1 md:mx-2">|</span>
                  <span>
                     {!isOnline ? city : null} {cat?.title ? ` | ${cat?.title}` : null}
                  </span>
               </div>
            </div>
         );
         const price: any = getPrice(list_price, discounted_price);
         const { getConvertedPriceWithSymbol } = useCurrency();
         const ConvertedPriceWithSymbol: any = getConvertedPriceWithSymbol(price);

         const discount = discounted_price > 0 &&
            list_price > 0 &&
            Number(discounted_price) !== Number(list_price) && (
               <div className="flex items-center">
                  <span className="text-sm line-through opacity-75 ltr:ml-1 rtl:mr-1">
                     {String(getConvertedPriceWithSymbol(list_price)).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                     )}
                  </span>
                  {/* <span className="py-0.5 px-1 -mt-1 text-xs text-green-primary bg-green-primary bg-opacity-10 ltr:ml-2 rtl:mr-2">
                  <Translate
                     id="common:discount"
                     values={{
                        amount: getDiscount(list_price, discounted_price),
                     }}
                  />
               </span> */}
               </div>
            );

         return (
            <SwiperSlide className="h-auto py-2" key={_id || id}>
               <Card
                  onClick={() => {
                     experienceClickDataLayer({
                        category: analytics_id || String(cat?.title),
                        event_location: city,
                        package_name: name,
                        package_price: getPrice(list_price, discounted_price),
                        event: analytics_event,
                     });
                     productClickImpressionDataLayer({
                        products: [
                           {
                              list: homepage ? 'Home Page' : 'Experience Listing',
                              brand: organizer_name || 'Hala Yalla',
                              category: cat?.title || '',
                              city,
                              name,
                              date: '',
                              id: _id,
                              position: 1,
                              price: getPrice(list_price, discounted_price),
                              weekend: false,
                           },
                        ],
                        user: {
                           email: user?.email || '',
                           user_id: user?.user_id || '',
                           login_status: authenticated ? 'login' : '',
                        },
                     });
                     setBrowsedExperience(_id || id);
                  }}
                  is_dmc={is_dmc}
                  category={_category}
                  category_slug={cat?.slug}
                  image={venue_image || images[0]}
                  title={name}
                  alt={alt}
                  isVerified={isVerified}
                  bookable={book_online === 'Yes'}
                  venueType={venue_type}
                  type={type}
                  venueTypeTitle={venue_type_title}
                  optimized_image={optimized_image}
                  venue_id={_id}
                  href={
                     type === 'events'
                        ? `/tickets/${event_is_group ? 'group/' : ''}${slug}`
                        : `/experiences/${city_slug || 'all-cities'}/${cat?.slug}/${slug}`
                  }>
                  <div className="flex flex-col md:flex-row justify-between p-4 pt-2 md:p-2 lg:h-[110px]">
                     <div className="md:max-w-[61%] mb-4 md:mb-0">
                        {_category}
                        <div className="line-clamp-2 font-semibold text-primary-color text-xl md:text-lg">
                           {name}
                        </div>
                     </div>
                     <div className="flex flex-row-reverse md:flex-col gap-2 md:max-w-[34%] items-center md:items-end justify-between leading-[17.33px] md:leading-[19.81px]">
                        <div className="ltr:text-right rtl:text-left -mt-1 whitespace-nowrap">
                           {price_start_from ? (
                              <span className="block text-sm md:text-base font-semibold gap-1">
                                 <Translate id="common:from" /> {price_start_from}
                              </span>
                           ) : venue_type !== 'offline_experience' ? (
                              <span className="flex flex-col items-end md:text-base">
                                 <span>{discount}</span>
                                 <span className="font-semibold">
                                    {list_price > 0 || discounted_price > 0 ? (
                                       <span>
                                          <Translate id="common:from" /> {ConvertedPriceWithSymbol}
                                       </span>
                                    ) : !is_external ? (
                                       <Translate id="common:free" />
                                    ) : null}
                                 </span>
                              </span>
                           ) : null}
                        </div>
                        <div className="flex flex-1 items-end">
                           <Button className="px-4 py-1.5 text-base font-semibold rounded-lg whitespace-nowrap">
                              <Translate id="common:book" />
                           </Button>
                        </div>
                     </div>
                  </div>
               </Card>
            </SwiperSlide>
         );
      }
   );
};

export default getExperienceSlider;
